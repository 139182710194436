import React from "react";

import { changeCDRTotalKg, selectTotalKg } from "../../store/cdrSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import InputField from "../common/InputField";

const RemovalAmountInput = () => {
  const dispatch = useAppDispatch();
  const totalKg = useAppSelector(selectTotalKg);
  function handleAmountChange(event: React.FormEvent<HTMLInputElement>) {
    const value = event.currentTarget.value;
    dispatch(changeCDRTotalKg(+value));
  }

  return (
    <div className="mb-3 flex flex-col items-center">
      <h3 className="font-bold text-2xl text-heading font-header mb-3">
        How much carbon dioxide would you like to remove in total?
      </h3>
      <InputField
        type="amount"
        fieldValue={totalKg}
        onChange={handleAmountChange}
      ></InputField>
    </div>
  );
};

export default RemovalAmountInput;
