import { Switch } from "@headlessui/react";
import React from "react";

import {
  changePurchaseMode,
  selectSubscriptionMode,
} from "../../store/cdrSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { PurchaseType } from "../../types/cdr-request.type";

const classNames = (...classes: Array<string>) => {
  return classes.filter(Boolean).join(" ");
};

const PurchaseModeToggle = () => {
  const subscriptionMode = useAppSelector(selectSubscriptionMode);
  const isSubscription = subscriptionMode === "SUBSCRIPTION_MONTHLY";

  const dispatch = useAppDispatch();

  const handleIsSubscription = (isSubscription: boolean) => {
    const purchaseMode: PurchaseType = isSubscription
      ? "SUBSCRIPTION_MONTHLY"
      : "ONE_TIME";
    dispatch(changePurchaseMode(purchaseMode));
  };

  return (
    <>
      <h4 className="font-semibold text-xl text-heading font-header text-left sm:text-center pt-4 pb-2">
        Choose if you would like to buy carbon removal one-off or set up a
        recurring purchase.
      </h4>
      <Switch.Group as="div" className="flex items-center justify-center">
        <Switch.Label as="span" className="ml-3 w-32 text-center">
          <p className="font-bold font-header text-gray-700">One time</p>
          <p className="italic text-sm">(once)</p>
        </Switch.Label>
        <Switch
          checked={isSubscription}
          onChange={handleIsSubscription}
          className={classNames(
            isSubscription
              ? "bg-primary focus:ring-primary"
              : "bg-secondary focus:ring-secondary",
            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2  focus:ring-offset-2"
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              isSubscription ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          />
        </Switch>
        <Switch.Label as="span" className="mr-3 w-32 text-center">
          <p className=" font-bold font-header text-gray-700">Subscription</p>
          <p className="italic text-sm">(monthly)</p>
        </Switch.Label>
      </Switch.Group>
    </>
  );
};

export default PurchaseModeToggle;
