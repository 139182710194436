import React, { FC } from "react";

import { BULK_PRICING_THRESHOLD_USD_CENTS } from "../../constants";
import "../../services/checkout.service";
import { changeModalState, selectTotalCost } from "../../store/cdrSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Alert from "./Alert";

const PurchaseButton: FC = () => {
  const totalCost = useAppSelector(selectTotalCost);
  const dispatch = useAppDispatch();

  const isMinCost = totalCost > 1000;
  const isMaxCost = totalCost > BULK_PRICING_THRESHOLD_USD_CENTS;

  const handleOnClick = () => {
    dispatch(changeModalState());
  };

  return (
    <>
      {!isMinCost && (
        <Alert type="error" message="Minimum purchase is 10 USD." />
      )}
      {isMaxCost && (
        <Alert
          type="info"
          message={
            <>
              For larger purchases{" "}
              <a
                className="underline hover:text-blue-400"
                href="/contact"
                target="_top"
              >
                contact us
              </a>{" "}
              for bulk discount.
            </>
          }
        />
      )}
      <div className="flex flex-col items-center justify-center mt-3">
        <button
          type="button"
          className="rounded-full border border-transparent bg-primary px-7 py-4 text-base font-bold font-header text-white shadow-sm 
                    hover:bg-white hover:text-primary hover:border-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 disabled:opacity-50 transition-all"
          disabled={!isMinCost}
          onClick={handleOnClick}
        >
          Buy now
        </button>
        <p className="text-gray-500">
          By choosing to buy with us you are agreeing to our{" "}
          <a
            className="underline hover:text-primary"
            href="https://carbonremoved.com/terms-and-conditions/"
            target="_top"
          >
            Terms and Conditions
          </a>
          .
        </p>
      </div>
    </>
  );
};

export default PurchaseButton;
