import React, { FC } from "react";

type Props = React.HTMLProps<HTMLInputElement> & {
  type?: "percentage" | "amount" | "email" | "text";
  label?: string;
  fieldValue?: string | number;
};

const InputField: FC<Props> = (props) => {
  const {
    type = "percentage",
    label = undefined,
    fieldValue,
    ...nativeElementProps
  } = props;

  return (
    <>
      {label && (
        <>
          <label htmlFor="price" className="flex items-center mb-2">
            {label}
          </label>
        </>
      )}
      {type === "email" && (
        <input
          {...nativeElementProps}
          type="text"
          name="email address"
          id="emailAddress"
          className="block w-full rounded-full border-0 px-5 py-2 text-gray-700 outline-none shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary  sm:leading-6 [-moz-appearance:_textfield] [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none"
          aria-describedby="email address"
          value={fieldValue}
        />
      )}
      {type === "text" && (
        <input
          {...nativeElementProps}
          type="text"
          className="block w-full rounded-full border-0 px-5 py-2 text-gray-700 outline-none shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary  sm:leading-6 [-moz-appearance:_textfield] [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none"
          value={fieldValue}
        />
      )}
      {type === "percentage" && (
        <div className="flex justify-end">
          <div className="relative w-20">
            <input
              {...nativeElementProps}
              type="number"
              name="percentage"
              id="percentage"
              className="text-right block w-full rounded-full border-0 px-4 py-2 sm:pr-9 text-gray-700 outline-none shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary  sm:leading-6 [-moz-appearance:_textfield] [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none"
              placeholder="50"
              aria-describedby="percentage"
              min="0"
              max="100"
              value={fieldValue}
            />
            <div className="hidden sm:flex absolute pointer-events-none inset-y-0 right-0 items-center pr-4">
              <span className="text-gray-500 " id="percentage">
                %
              </span>
            </div>
          </div>
        </div>
      )}
      {type === "amount" && (
        <div className="relative">
          <input
            {...nativeElementProps}
            type="number"
            name="amount"
            id="amount"
            className="text-right block w-full rounded-full border-0 px-9 py-2 text-gray-700 outline-none shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary  sm:leading-6 [-moz-appearance:_textfield] [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none"
            placeholder="50"
            aria-describedby="amount"
            min="0"
            value={fieldValue}
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span className="text-gray-500 " id="amount">
              kg
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default InputField;
