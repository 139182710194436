import React from "react";

import { changeCDRTotalKg } from "../../store/cdrSlice";
import { useAppDispatch } from "../../store/hooks";

const ExampleCDRAmounts = () => {
  const dispatch = useAppDispatch();
  const handleOnClick = (amount: number) => {
    dispatch(changeCDRTotalKg(amount));
  };
  const emissionExamples = [
    {
      id: 0,
      name: "Flight from Bangalore to San Francisco (17.5h per way)",
      description:
        "This return flight in a Boeing 777-200LR creates 2,393 kg of direct CO₂ emissions and 4,691 kg of indirect CO₂ emissions for one person flying economy.",
      amount: 7000,
      icon: "🛫",
    },
    {
      id: 1,
      name: "Flight from London to Tokyo (14.5h per way)",
      description:
        "This return flight in a Boeing 777-300 creates 1,433 kg of direct CO₂ emissions and 2,786 kg of indirect CO₂ emissions for one person flying economy.",
      amount: 4000,
      icon: "🛫",
    },
    {
      id: 2,
      name: "Flight from New York to San Franciso (6.5h per way)",
      description:
        "This return flight in a Airbus A320 creates 464 kg of direct CO₂ emissions and 869 kg of indirect CO₂ emissions for one person flying economy.",
      amount: 1300,
      icon: "🛫",
    },
    {
      id: 3,
      name: "Flight from Zurich to London (2h per way)",
      description:
        "This return flight in a Airbus A320 creates 126 kg of direct CO₂ emissions and 170 kg of indirect CO₂ emissions for one person flying economy.",
      amount: 300,
      icon: "🛫",
    },
  ];

  return (
    <>
      <div className="mt-8 flex flex-col max-w-7xl m-auto">
        <h3 className="font-bold text-2xl text-heading font-header text-left sm:text-center">
          Would you like to remove the emissions of a flight?
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-5">
          {emissionExamples.map((example) => (
            <button
              key={example.id}
              onClick={() => handleOnClick(example.amount)}
            >
              <h3 className="text-6xl">{example.icon}</h3>
              <h4 className="font-bold text-xl text-heading font-header text-center my-2">
                {example.name}
              </h4>
              <h1 className="font-bold text-4xl my-2">{example.amount} kg</h1>
              <p className="text-sm">{example.description}</p>
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default ExampleCDRAmounts;
