import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";

import { checkoutService } from "../services/checkout.service";
import cdrReducer from "./cdrSlice";

export const store = configureStore({
  reducer: {
    cdr: cdrReducer,
    [checkoutService.reducerPath]: checkoutService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(checkoutService.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
