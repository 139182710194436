import React from "react";
import { Provider } from "react-redux";

import { Modal } from "./components/common/Modal";
import PurchaseButton from "./components/common/PurchaseButton";
import ExampleCDRAmounts from "./components/features/ExampleCDRAmounts";
import FootprintPerCapitaSelect from "./components/features/FootprintPerCapitaSelect";
import PurchaseModeToggle from "./components/features/PurchaseModeToggle";
import RemovalAmountInput from "./components/features/RemovalAmountInput";
import RemovalTable from "./components/features/RemovalTable";
import { store } from "./store/store";

function App() {
  return (
    <Provider store={store}>
      <main>
        <RemovalAmountInput />
        <FootprintPerCapitaSelect />
        <PurchaseModeToggle />
        <RemovalTable />
        <PurchaseButton />
        <Modal />
        <ExampleCDRAmounts />
      </main>
    </Provider>
  );
}

export default App;
