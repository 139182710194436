import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { FirebaseError } from "firebase/app";
import { ZodError } from "zod";

import { createLemonSqueezyCheckout } from "../firebase";
import { CDRRequest, validateCDRRequest } from "../types/cdr-request.type";
import { LemonSqueezyCheckoutResponse } from "../types/response.type";

export const checkoutService = createApi({
  baseQuery: fakeBaseQuery(),
  endpoints: (build) => ({
    createCheckoutSession: build.mutation<
      LemonSqueezyCheckoutResponse,
      CDRRequest
    >({
      queryFn: async (cdrRequest: CDRRequest) => {
        try {
          const validatedRequest = validateCDRRequest(cdrRequest);
          const response = await createLemonSqueezyCheckout(validatedRequest);
          return { data: response.data };
        } catch (err: unknown) {
          console.error(err);
          if (err instanceof ZodError) {
            return { error: err.issues };
          }
          if (err instanceof FirebaseError) {
            return {
              error: {
                code: err.code,
                message: err.message,
              },
            };
          }
          return { error: err };
        }
      },
    }),
  }),
});

export const { useCreateCheckoutSessionMutation } = checkoutService;
