import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  CARBON_METHOD_COST_USD_CENTS_PER_TONNE,
  CDRMethod,
  CLIMACRUX_FEES_PCT,
  MINIMUM_CLIMACRUX_FEES_USD_CENTS,
} from "../constants";
import { PurchaseType } from "../types/cdr-request.type";
import { CDRState } from "../types/types";
import { calculateTotalCost } from "../utils/pricing.util";
import { fetchCount } from "./counterAPI";
import { RootState } from "./store";

const initialState: CDRState = {
  purchaseMode: "SUBSCRIPTION_MONTHLY",
  cdrTotalKg: 500,
  cdrMix: {
    [CDRMethod.FORESTATION]: {
      amountPct: 25,
    },
    [CDRMethod.BIO_OIL]: {
      amountPct: 25,
    },
    [CDRMethod.KELP_SINKING]: {
      amountPct: 25,
    },
    [CDRMethod.OLIVINE]: {
      amountPct: 25,
    },
    [CDRMethod.DACS]: {
      amountPct: 0,
    },
  },
  modalOpened: false,
  emailAddress: "",
  certificateDisplayName: "",
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk(
  "counter/fetchCount",
  async (amount: number) => {
    const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const cdrSlice = createSlice({
  name: "cdr",
  initialState,
  reducers: {
    changePurchaseMode: (state, action: PayloadAction<PurchaseType>) => {
      state.purchaseMode = action.payload;
    },
    changeCDRTotalKg: (state, action: PayloadAction<number>) => {
      state.cdrTotalKg = action.payload;
    },
    changeCDRBreakdown: (
      state,
      action: PayloadAction<{ method: CDRMethod; amountPct: number }>
    ) => {
      state.cdrMix[action.payload.method] = {
        amountPct: action.payload.amountPct,
      };
    },
    changeModalState: (state) => {
      state.modalOpened = !state.modalOpened;
    },
    changeEmailAddress: (state, action: PayloadAction<string>) => {
      state.emailAddress = action.payload;
    },
    changeCertificateDisplayName: (state, action: PayloadAction<string>) => {
      state.certificateDisplayName = action.payload;
    },
  },
});

export const {
  changeModalState,
  changePurchaseMode,
  changeCDRBreakdown,
  changeCDRTotalKg,
  changeEmailAddress,
  changeCertificateDisplayName,
} = cdrSlice.actions;

// Note: Returns the price in USD cents.
// If wanting to show in USD, divide by 100.
export const calculateCDRItemFee = (
  totalKg: number,
  removalMethodPct: number,
  method: CDRMethod
): number => {
  return Math.ceil(
    (totalKg / 1000) *
      (removalMethodPct / 100) *
      CARBON_METHOD_COST_USD_CENTS_PER_TONNE[method].USD
  );
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTotalKg = (state: RootState) => state.cdr.cdrTotalKg;
export const selectTotalPct = (state: RootState) =>
  Object.values(state.cdr.cdrMix)
    .map((x) => x.amountPct)
    .reduce((prev, cur) => prev + cur, 0);
export const selectBreakdown = (state: RootState) => state.cdr.cdrMix;

export const selectCDRFee = (state: RootState) =>
  Object.keys(selectBreakdown(state))
    .map((k) => {
      const method = k as CDRMethod;
      const methodBreakdown = selectBreakdown(state)[method];
      return calculateCDRItemFee(
        selectTotalKg(state),
        methodBreakdown.amountPct,
        method
      );
    })
    .reduce((prev, cur) => prev + cur, 0);

export const selectClimacruxFee = (state: RootState) => {
  const totalCost = selectTotalCost(state);
  return Math.max(
    MINIMUM_CLIMACRUX_FEES_USD_CENTS,
    totalCost * CLIMACRUX_FEES_PCT
  );
};

export const selectTotalCost = (state: RootState) => {
  const removalCostCents = selectCDRFee(state);
  const totalCost = calculateTotalCost(removalCostCents);
  return totalCost;
};

export const selectSubscriptionMode = (state: RootState) =>
  state.cdr.purchaseMode;

export const selectModalOpened = (state: RootState) => state.cdr.modalOpened;
export const selectEmailAddress = (state: RootState) => state.cdr.emailAddress;
export const selectCertificateDisplayName = (state: RootState) =>
  state.cdr.certificateDisplayName;
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//     (dispatch, getState) => {
//       const currentValue = selectCount(getState());
//       if (currentValue % 2 === 1) {
//         dispatch(incrementByAmount(amount));
//       }
//     };

export default cdrSlice.reducer;
