import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect } from "react";

import { CDRMethod } from "../../constants";
import useInput from "../../hooks/use-input";
import { useCreateCheckoutSessionMutation } from "../../services/checkout.service";
import {
  changeCertificateDisplayName,
  changeEmailAddress,
  changeModalState,
  selectBreakdown,
  selectCertificateDisplayName,
  selectEmailAddress,
  selectModalOpened,
  selectSubscriptionMode,
  selectTotalKg,
} from "../../store/cdrSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Alert from "./Alert";
import InputField from "./InputField";

export const Modal = () => {
  const isNameValid = (value: string) =>
    value.trim() !== "" && !!value.match(/^[a-zA-Z\s]+$/);

  const isEmailValid = (value: string) => {
    const lastAtPos = value.lastIndexOf("@");
    const lastDotPos = value.lastIndexOf(".");
    return (
      value.trim() !== "" &&
      lastAtPos < lastDotPos &&
      lastAtPos > 0 &&
      value.indexOf("@@") === -1 &&
      lastDotPos > 2 &&
      value.length - lastDotPos > 2
    );
  };

  const {
    value: enteredEmail,
    hasError: emailInputHasError,
    isTouched: emailInputIsTouched,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(isEmailValid);

  const {
    value: enteredCertificateDisplayName,
    hasError: displayNameInputHasError,
    isTouched: displayNameInputIsTouched,
    valueChangeHandler: displayNameChangeHandler,
    inputBlurHandler: displayNameBlurHandler,
  } = useInput(isNameValid);

  const modalOpened = useAppSelector(selectModalOpened);

  const totalKg = useAppSelector(selectTotalKg);
  const breakdown = useAppSelector(selectBreakdown);
  const emailAddress = useAppSelector(selectEmailAddress);
  const certificateDisplayName = useAppSelector(selectCertificateDisplayName);
  const subscriptionMode = useAppSelector(selectSubscriptionMode);

  const dispatch = useAppDispatch();
  const [
    createCheckoutSession,
    {
      data: checkoutData,
      isLoading: checkoutIsLoading,
      isError: checkoutIsError,
    },
  ] = useCreateCheckoutSessionMutation();
  const handleOnClick = () => {
    dispatch(changeModalState());
  };

  useEffect(() => {
    dispatch(changeEmailAddress(enteredEmail));
  }, [enteredEmail, dispatch]);

  useEffect(() => {
    dispatch(changeCertificateDisplayName(enteredCertificateDisplayName));
  }, [dispatch, enteredCertificateDisplayName]);

  const formSubmissionHandler = () => {
    const methodKg = (method: CDRMethod) => {
      return totalKg * (breakdown[method].amountPct / 100);
    };

    createCheckoutSession({
      email: emailAddress,
      purchaseType: subscriptionMode,
      certificateDisplayName,
      breakdown: {
        [CDRMethod.BIO_OIL]: { kg: methodKg(CDRMethod.BIO_OIL) },
        [CDRMethod.DACS]: { kg: methodKg(CDRMethod.DACS) },
        [CDRMethod.FORESTATION]: { kg: methodKg(CDRMethod.FORESTATION) },
        [CDRMethod.KELP_SINKING]: { kg: methodKg(CDRMethod.KELP_SINKING) },
        [CDRMethod.OLIVINE]: { kg: methodKg(CDRMethod.OLIVINE) },
      },
    });
  };

  useEffect(() => {
    if (checkoutData) {
      console.log(checkoutData);
      if (window.top) {
        // Navigate to the provided session URL
        window.top.location.href = checkoutData.sessionUrl;
      }
    }
  }, [checkoutData]);

  return (
    <Transition.Root show={modalOpened} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleOnClick}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                    onClick={handleOnClick}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="font-black font-header text-2xl leading-6 text-gray-700"
                  >
                    Purchase details
                  </Dialog.Title>
                  <div className="flex flex-col gap-2 mt-4 w-full">
                    <InputField
                      type="email"
                      label="Email address"
                      placeholder="email@example.com"
                      fieldValue={emailAddress}
                      onChange={emailChangeHandler}
                      onBlur={emailBlurHandler}
                      disabled={checkoutIsLoading}
                    />
                    <div className="h-5">
                      {emailInputHasError && (
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <XCircleIcon
                              className="h-5 w-5 text-red-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-2">
                            <h3 className=" font-medium text-red-800">
                              Email address not valid.
                            </h3>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 mt-4 w-full">
                    <InputField
                      type="text"
                      label="Certificate display name"
                      placeholder="Jackie Doe"
                      fieldValue={certificateDisplayName}
                      onChange={displayNameChangeHandler}
                      onBlur={displayNameBlurHandler}
                      disabled={checkoutIsLoading}
                    />
                    <div className="h-5">
                      {displayNameInputHasError && (
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <XCircleIcon
                              className="h-5 w-5 text-red-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-2">
                            <h3 className=" font-medium text-red-800">
                              Certificate display name not valid.
                            </h3>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex items-center rounded-full border border-transparent bg-primary px-7 py-4 text-base font-bold font-header text-white shadow-sm 
                    hover:bg-white hover:text-primary hover:border-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 disabled:opacity-50 transition-all"
                    disabled={
                      !emailInputIsTouched ||
                      (emailInputIsTouched && emailInputHasError) ||
                      !displayNameInputIsTouched ||
                      (displayNameInputIsTouched && displayNameInputHasError) ||
                      checkoutIsLoading
                    }
                    onClick={formSubmissionHandler}
                  >
                    Go to checkout
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center rounded-full border bg-white mx-2 px-7 py-4 text-base font-bold text-gray-700 shadow-sm hover:bg-gray-50 ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 disabled:opacity-50"
                    onClick={handleOnClick}
                  >
                    Cancel
                  </button>
                </div>
                {checkoutIsError && (
                  <div className="mt-4">
                    <Alert
                      type="error"
                      message="Something went wrong. Please try again."
                    />
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
