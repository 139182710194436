// add the fonts that we need for heading and body
import "@fontsource/inter/variable-full.css";
import "@fontsource/nunito/variable.css";
// enable auto resizing in the parent iframe
// https://github.com/davidjbradshaw/iframe-resizer
import "iframe-resizer/js/iframeResizer.contentWindow";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
