import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import React from "react";

interface AccordionProps {
  list: {
    id: number;
    title: string | React.ReactNode;
    content: string | React.ReactNode;
  }[];
}

const Accordion = ({ list }: AccordionProps) => {
  return (
    <div className="mx-auto max-w-7xl">
      <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
        <dl className="pace-y-6 divide-y divide-gray-900/10">
          {list.map((item) => (
            <Disclosure as="div" key={item.id} className="pt-6">
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                      {item.title}
                      <span className="ml-6 flex h-7 items-center">
                        {open ? (
                          <MinusSmallIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <PlusSmallIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    <div className="flex flex-col items-center">
                      {item.content}
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default Accordion;
