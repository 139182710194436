import React, { ReactNode } from "react";

import { ReactComponent as BioOil } from "../../assets/icons/bio-oil.svg";
import { ReactComponent as Forestation } from "../../assets/icons/forestation.svg";
import { ReactComponent as Kelp } from "../../assets/icons/kelp.svg";
import { ReactComponent as Olivine } from "../../assets/icons/olivine.svg";
import { CDRMethod } from "../../constants";
import {
  calculateCDRItemFee,
  changeCDRBreakdown,
  selectBreakdown,
  selectClimacruxFee,
  selectTotalCost,
  selectTotalKg,
  selectTotalPct,
} from "../../store/cdrSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import InputField from "../common/InputField";

type CDRListElement = {
  id: number;
  name: string;
  icon?: JSX.Element;
  method?: CDRMethod;
};

export default function RemovalTable() {
  const totalKg = useAppSelector(selectTotalKg);
  const breakdown = useAppSelector(selectBreakdown);
  const totalPct = useAppSelector(selectTotalPct);
  const climacruxFee = useAppSelector(selectClimacruxFee);
  const totalFee = useAppSelector(selectTotalCost);

  const cdrList: Array<CDRListElement> = [
    {
      id: 1,
      icon: <BioOil />,
      method: CDRMethod.BIO_OIL,
      name: "Underground bio-oil injection",
    },
    {
      id: 2,
      icon: <Forestation />,
      method: CDRMethod.FORESTATION,
      name: "Afforestation through planting trees",
    },
    {
      id: 3,
      icon: <Kelp />,
      method: CDRMethod.KELP_SINKING,
      name: "Blue carbon through kelp sinking",
    },
    {
      id: 4,
      icon: <Olivine />,
      method: CDRMethod.OLIVINE,
      name: "Enhanced weathering through olivine",
    },
    { id: 5, name: "Fees" },
    { id: 6, name: "Total" },
  ];

  const dispatch = useAppDispatch();

  function handlePctChange(method: CDRMethod) {
    const updateBreakdown = (event: React.FormEvent<HTMLInputElement>) => {
      const amountPct = +event.currentTarget.value;
      dispatch(changeCDRBreakdown({ method, amountPct }));
    };
    return updateBreakdown;
  }

  function loadMethodColumn(name: string, icon?: JSX.Element) {
    switch (name) {
      case "Fees":
        return (
          <>
            <span className="text-heading">{name}</span>
          </>
        );
      case "Total":
        return (
          <>
            <span className="text-heading font-bold">{name}</span>
          </>
        );
      default:
        return (
          <>
            <div className="hidden sm:flex w-[3rem]">{icon}</div>
            <span className="text-heading sm:pl-3">{name}</span>
          </>
        );
    }
  }

  function loadPercentageColumn(name: string, method?: CDRMethod): ReactNode {
    switch (name) {
      case "Fees":
        return "";
      case "Total":
        return <span className="font-bold pr-5">{totalPct} %</span>;
      default:
        return (
          method && (
            <InputField
              type="percentage"
              fieldValue={breakdown[method].amountPct}
              onChange={handlePctChange(method)}
            ></InputField>
          )
        );
    }
  }

  function loadAmountColumn(name: string, method?: CDRMethod) {
    switch (name) {
      case "Fees":
        return "";
      case "Total":
        return (
          <span className="font-bold">
            {new Intl.NumberFormat("en-US").format(totalKg)}{" "}
            <span className="ml-1">kg</span>
          </span>
        );
      default:
        return (
          <>
            {method &&
              new Intl.NumberFormat("en-US").format(
                (totalKg * breakdown[method].amountPct) / 100
              )}{" "}
            <span className="hidden sm:inline ml-1">kg</span>
          </>
        );
    }
  }

  function loadCostColumn(name: string, method?: CDRMethod) {
    switch (name) {
      case "Fees":
        return (
          <>
            <span className="hidden sm:block">
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(Math.ceil(climacruxFee) / 100)}
            </span>
            <span className="block sm:hidden">
              {new Intl.NumberFormat("en-US").format(
                Math.ceil(climacruxFee) / 100
              )}
            </span>
          </>
        );
      case "Total":
        return (
          <>
            <span className="hidden sm:block font-bold">
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(Math.ceil(totalFee) / 100)}
            </span>
            <span className="block sm:hidden font-bold">
              {new Intl.NumberFormat("en-US").format(Math.ceil(totalFee) / 100)}
            </span>
          </>
        );

      default:
        if (method) {
          return (
            <>
              <span className="hidden sm:block">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(
                  Math.ceil(
                    calculateCDRItemFee(
                      totalKg,
                      breakdown[method].amountPct,
                      method
                    )
                  ) / 100
                )}
              </span>
              <span className="block sm:hidden">
                {new Intl.NumberFormat("en-US").format(
                  Math.ceil(
                    calculateCDRItemFee(
                      totalKg,
                      breakdown[method].amountPct,
                      method
                    )
                  ) / 100
                )}
              </span>
            </>
          );
        }
    }
  }

  return (
    <>
      <div className="mt-8">
        <h3 className="font-bold text-2xl text-heading font-header text-left sm:text-center">
          How would you like to remove your carbon dioxide?
        </h3>
        <h4 className="font-semibold text-xl text-heading font-header text-left sm:text-center">
          Tweak the percentage of each removal method to your liking.
        </h4>
      </div>
      <div className="grid grid-cols-4 gap-2 mt-8 place-content-between grid-flow-row max-w-7xl m-auto">
        <div className="grid col-span-4 border-t-2"></div>
        <h4 className="font-bold font-header text-heading text-left">
          <span className="hidden md:block">Removal method</span>
          <span className="md:hidden">Method</span>
        </h4>
        <h4 className="font-bold font-header text-heading text-right">
          <span className="hidden md:block pr-5">Percentage [%]</span>
          <span className="md:hidden pr-5">%</span>
        </h4>
        <h4 className="font-bold font-header text-heading text-right">
          <span className="hidden md:block">Amount [Kg]</span>
          <span className="md:hidden">Kg</span>
        </h4>
        <h4 className="font-bold font-header text-heading text-right">
          <span className="hidden md:block">Cost [USD]</span>
          <span className="md:hidden">USD</span>
        </h4>
        {cdrList.map((cdrListItem) => (
          <React.Fragment key={cdrListItem.id}>
            <div className="grid col-span-4 border-t-2"></div>
            <div className="flex text-heading items-center">
              {loadMethodColumn(cdrListItem.name, cdrListItem.icon)}
            </div>
            <div className="flex items-center justify-end whitespace-nowrap py-4 text-right pr-1">
              {loadPercentageColumn(cdrListItem.name, cdrListItem.method)}
            </div>
            <p className="flex items-center justify-end whitespace-nowrap py-4 text-right">
              {loadAmountColumn(cdrListItem.name, cdrListItem.method)}
            </p>
            <p className="flex items-center justify-end whitespace-nowrap py-4 text-right">
              {loadCostColumn(cdrListItem.name, cdrListItem.method)}
            </p>
          </React.Fragment>
        ))}
      </div>
    </>
  );
}
