export const COLLECTION_NAME_PURCHASES = "purchases";

export const enum CDRMethod {
  FORESTATION = "forestation",
  BIO_OIL = "bio-oil",
  DACS = "dacs",
  OLIVINE = "olivine",
  KELP_SINKING = "kelp-sinking",
}

// $10 minimum purchase amount
export const MINIMUM_PURCHASE_USD_CENTS = 1000;

// $5 minimum Climacrux fees
export const MINIMUM_CLIMACRUX_FEES_USD_CENTS = 500;

// 15% Climacrux fees which includes 8% for payment processing and 7% for Climacrux
export const CLIMACRUX_FEES_PCT = 0.15;

// The threshold amount that must be met for the minimum Climacrux fees to be crossed
export const CLIMACRUX_FEES_THRESHOLD_USD_CENTS =
  (1 / CLIMACRUX_FEES_PCT) * MINIMUM_CLIMACRUX_FEES_USD_CENTS;

// If buying >$2000, contact us for bulk pricing
export const BULK_PRICING_THRESHOLD_USD_CENTS = 200000;

export const CARBON_METHOD_COST_USD_CENTS_PER_TONNE = {
  [CDRMethod.FORESTATION]: {
    USD: 100 * 36.78, // $36.78/tonne converted to USD Cents (OneTreePlanted $1 = 1 tree)
  },
  [CDRMethod.BIO_OIL]: {
    USD: 100 * 600, // $600/tonne converted to USD Cents
  },
  [CDRMethod.DACS]: {
    USD: 100 * 1115.48, // $1,115.48/tonne converted to USD Cents
  },
  [CDRMethod.OLIVINE]: {
    USD: 100 * 115, // $115/tonne converted to USD Cents
  },
  [CDRMethod.KELP_SINKING]: {
    USD: 100 * 350, // $350/tonne converted to USD Cents
  },
};

export const CARBON_METHOD_NAMES = {
  [CDRMethod.FORESTATION]: {
    long: "Forestation",
    short: "Forestation",
  },
  [CDRMethod.BIO_OIL]: {
    long: "Bio-oil",
    short: "Bio-oil",
  },
  [CDRMethod.DACS]: {
    long: "Direct Air Capture and Storage",
    short: "DACS",
  },
  [CDRMethod.OLIVINE]: {
    long: "Olivine Mineralization",
    short: "Olivine",
  },
  [CDRMethod.KELP_SINKING]: {
    long: "Kelp Sinking",
    short: "Kelp",
  },
};
