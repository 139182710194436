import {
  CARBON_METHOD_COST_USD_CENTS_PER_TONNE,
  CDRMethod,
  CLIMACRUX_FEES_PCT,
  CLIMACRUX_FEES_THRESHOLD_USD_CENTS,
  MINIMUM_CLIMACRUX_FEES_USD_CENTS,
} from "../constants";
import {
  CDRBreakdown,
  CDRRequest,
  CDRRequestExtended,
} from "../types/cdr-request.type";
import { kgOfMethod } from "./weight.util";

// Given a removal cost in cents and whether or not to include payment processing fees, return the total cost in cents.
export const calculateFees = (removalCostCents: number): number => {
  const totalCost = calculateTotalCost(removalCostCents);
  return Math.max(
    MINIMUM_CLIMACRUX_FEES_USD_CENTS,
    totalCost * CLIMACRUX_FEES_PCT
  );
};

// Given a removal cost in cents, return the total cost (including fees) in cents.
export const calculateTotalCost = (removalCostCents: number): number => {
  let totalCost: number;
  // Ensure removalCostCents is non-negative
  removalCostCents = Math.max(0, removalCostCents);

  // e.g. if removalCostCents = $40, should return $45
  if (
    removalCostCents <
    CLIMACRUX_FEES_THRESHOLD_USD_CENTS - MINIMUM_CLIMACRUX_FEES_USD_CENTS
  ) {
    totalCost = removalCostCents + MINIMUM_CLIMACRUX_FEES_USD_CENTS;
  } else {
    totalCost = removalCostCents / (1 - CLIMACRUX_FEES_PCT);
  }

  return totalCost;
};

export const addCostInfo = (cdrRequest: CDRRequest): CDRRequestExtended => {
  return {
    ...cdrRequest,
    fees: calculateFeesUSDCents(cdrRequest),
    totalCost: calculateTotalCostUSDCents(cdrRequest),
    removalCost: {
      [CDRMethod.BIO_OIL]: calculateCostBioOil(
        kgOfMethod(cdrRequest.breakdown, CDRMethod.BIO_OIL)
      ),
      [CDRMethod.DACS]: calculateCostDACS(
        kgOfMethod(cdrRequest.breakdown, CDRMethod.DACS)
      ),
      [CDRMethod.FORESTATION]: calculateCostForestation(
        kgOfMethod(cdrRequest.breakdown, CDRMethod.FORESTATION)
      ),
      [CDRMethod.KELP_SINKING]: calculateCostKelpSinking(
        kgOfMethod(cdrRequest.breakdown, CDRMethod.KELP_SINKING)
      ),
      [CDRMethod.OLIVINE]: calculateCostOlivine(
        kgOfMethod(cdrRequest.breakdown, CDRMethod.OLIVINE)
      ),
    },
  };
};

export const calculateRemovalCostUSDCents = (
  cdrBreakdown: CDRBreakdown
): number => {
  return (
    calculateCostBioOil(kgOfMethod(cdrBreakdown, CDRMethod.BIO_OIL)) +
    calculateCostDACS(kgOfMethod(cdrBreakdown, CDRMethod.DACS)) +
    calculateCostForestation(kgOfMethod(cdrBreakdown, CDRMethod.FORESTATION)) +
    calculateCostKelpSinking(kgOfMethod(cdrBreakdown, CDRMethod.KELP_SINKING)) +
    calculateCostOlivine(kgOfMethod(cdrBreakdown, CDRMethod.OLIVINE))
  );
};

export const calculateFeesUSDCents = (cdrRequest: CDRRequest): number => {
  const removalCost = calculateRemovalCostUSDCents(cdrRequest.breakdown);
  return Math.ceil(calculateFees(removalCost));
};

export const calculateTotalCostUSDCents = (cdrRequest: CDRRequest): number => {
  const removalCost = calculateRemovalCostUSDCents(cdrRequest.breakdown);
  return Math.ceil(calculateTotalCost(removalCost));
};

// A type helper for calculating the cost of removal.
// Takes in the amount of CO2 to be removed in kg and returns the cost of removal in USD Cents.
export type CDRMethodCostCalculator = (amountKg: number) => number;

// Helper method to calculate the cost of removal using forestation
export const calculateCostForestation: CDRMethodCostCalculator = (
  amountKg: number
): number => {
  return Math.ceil(
    CARBON_METHOD_COST_USD_CENTS_PER_TONNE[CDRMethod.FORESTATION].USD *
      (amountKg / 1000)
  );
};

// Helper method to calculate the cost of removal using bio-oil
export const calculateCostBioOil: CDRMethodCostCalculator = (
  amountKg: number
): number => {
  return Math.ceil(
    CARBON_METHOD_COST_USD_CENTS_PER_TONNE[CDRMethod.BIO_OIL].USD *
      (amountKg / 1000)
  );
};

// Helper method to calculate the cost of removal using DACS
export const calculateCostDACS: CDRMethodCostCalculator = (
  amountKg: number
): number => {
  return Math.ceil(
    CARBON_METHOD_COST_USD_CENTS_PER_TONNE[CDRMethod.DACS].USD *
      (amountKg / 1000)
  );
};

// Helper method to calculate the cost of removal using olivine mineralization
export const calculateCostOlivine: CDRMethodCostCalculator = (
  amountKg: number
): number => {
  return Math.ceil(
    CARBON_METHOD_COST_USD_CENTS_PER_TONNE[CDRMethod.OLIVINE].USD *
      (amountKg / 1000)
  );
};

// Helper method to calculate the cost of removal using kelp sinking
export const calculateCostKelpSinking: CDRMethodCostCalculator = (
  amountKg: number
): number => {
  return Math.ceil(
    CARBON_METHOD_COST_USD_CENTS_PER_TONNE[CDRMethod.KELP_SINKING].USD *
      (amountKg / 1000)
  );
};
