import { z } from "zod";

import { CDRMethod } from "../constants";

const PurchaseTypeEnum = z.enum([
  "ONE_TIME",
  "SUBSCRIPTION_MONTHLY",
  "SUBSCRIPTION_ANNUAL",
]);

// Used mostly as a small check, we still validate this on the backend.
const CDRMethodSchema = z
  .object({
    kg: z.number().nonnegative(),
  })
  .optional();

export const CDRRequestBreakdownSchema = z.object({
  [CDRMethod.BIO_OIL]: CDRMethodSchema,
  [CDRMethod.DACS]: CDRMethodSchema,
  [CDRMethod.FORESTATION]: CDRMethodSchema,
  [CDRMethod.KELP_SINKING]: CDRMethodSchema,
  [CDRMethod.OLIVINE]: CDRMethodSchema,
});

const CDRRequestSchema = z.object({
  email: z.string().email(),
  certificateDisplayName: z.string(),
  purchaseType: PurchaseTypeEnum,
  breakdown: CDRRequestBreakdownSchema,
});

// All costs are in USD cents.
const CDRRequestCostSchema = z.object({
  fees: z.number().int().positive(),
  totalCost: z.number().int().positive(),
  removalCost: z.object({
    [CDRMethod.BIO_OIL]: z.number().int().positive(),
    [CDRMethod.DACS]: z.number().int().positive(),
    [CDRMethod.FORESTATION]: z.number().int().positive(),
    [CDRMethod.KELP_SINKING]: z.number().int().positive(),
    [CDRMethod.OLIVINE]: z.number().int().positive(),
  }),
});

export type PurchaseType = z.infer<typeof PurchaseTypeEnum>;

export const CDRRequestExtendedSchema =
  CDRRequestSchema.merge(CDRRequestCostSchema);

export type CDRBreakdown = z.infer<typeof CDRRequestBreakdownSchema>;

export type CDRRequest = z.infer<typeof CDRRequestSchema>;

export type CDRRequestCost = z.infer<typeof CDRRequestCostSchema>;

export type CDRRequestExtended = z.infer<typeof CDRRequestExtendedSchema>;

// Function to validate a request body and, if valid, return a populated CDRRequest object.
export const validateCDRRequest = (request: unknown): CDRRequest => {
  return CDRRequestSchema.parse(request);
};
