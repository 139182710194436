import React from "react";

import emissionsPerCapita from "../../data/emissions_per_capita.json";
import Accordion from "../common/Accordion";
import Select from "../common/Select";

const FootprintPerCapitaSelect = () => {
  const list = [
    {
      id: 1,
      title: (
        <h4 className="font-semibold text-xl text-heading font-header text-left sm:text-center">
          Need help in deciding how much carbon dioxide to remove?{" "}
        </h4>
      ),
      content: <Select list={emissionsPerCapita}></Select>,
    },
  ];
  return (
    <>
      <div className="flex flex-col items-center max-w-7xl m-auto">
        <Accordion list={list}></Accordion>
      </div>
    </>
  );
};

export default FootprintPerCapitaSelect;
