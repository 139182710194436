import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useState } from "react";

import { changeCDRTotalKg, changePurchaseMode } from "../../store/cdrSlice";
import { useAppDispatch } from "../../store/hooks";
import Emission from "../../types/Emissions";
import { PurchaseType } from "../../types/cdr-request.type";

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

type Props = {
  list: Emission[];
};

const Select = ({ list }: Props) => {
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<Emission>(list[205]);
  const handleOnClick = (amount: number, purchaseMode: PurchaseType) => {
    dispatch(changeCDRTotalKg(amount));
    dispatch(changePurchaseMode(purchaseMode));
  };

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative mt-2 w-60">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{selected.country}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {list.map((item: Emission) => (
                  <Listbox.Option
                    key={item.country}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {item.country}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
          <div>
            <p className="text-center">
              Average yearly CO₂ emissions per person in {selected.country}
            </p>
            <div className="flex justify-center my-2">
              <button
                type="button"
                className="rounded-full border border-transparent bg-primary mx-1 px-7 py-2 text-base font-bold font-header text-white shadow-sm 
                    hover:bg-white hover:text-primary hover:border-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 disabled:opacity-50 transition-all"
                onClick={() =>
                  handleOnClick(+selected.emissions_kg.toFixed(), "ONE_TIME")
                }
              >
                <p className="font-bold font-header m-0">
                  {new Intl.NumberFormat("en-US").format(
                    +selected.emissions_kg.toFixed()
                  )}{" "}
                  kg
                </p>
                <p className="italic text-sm m-0">(remove in one)</p>
              </button>
              <button
                type="button"
                className="rounded-full border border-transparent bg-primary mx-1 px-7 py-2 text-base font-bold font-header text-white shadow-sm 
                    hover:bg-white hover:text-primary hover:border-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 disabled:opacity-50 transition-all"
                onClick={() =>
                  handleOnClick(
                    +(selected.emissions_kg / 12).toFixed(),
                    "SUBSCRIPTION_MONTHLY"
                  )
                }
              >
                <p className="font-bold font-header m-0">
                  {new Intl.NumberFormat("en-US").format(
                    +(selected.emissions_kg / 12).toFixed()
                  )}{" "}
                  kg
                </p>
                <p className="italic text-sm m-0">(remove monthly)</p>
              </button>
            </div>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default Select;
