import { InformationCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import React, { FC } from "react";

type Props = {
  type?: "error" | "info";
  message: string | JSX.Element;
};

const Alert: FC<Props> = ({ type = "info", message }: Props) => {
  let icon = <></>;
  let backgroundColor = "";
  let fontColor = "";

  switch (type) {
    case "error":
      icon = (
        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      );
      backgroundColor = "bg-red-50";
      fontColor = "text-red-800";
      break;
    case "info":
      icon = (
        <InformationCircleIcon
          className="h-5 w-5 text-blue-400"
          aria-hidden="true"
        />
      );
      backgroundColor = "bg-blue-50";
      fontColor = "text-blue-700";
      break;
    default:
      break;
  }

  return (
    <div className={"rounded-md p-4 max-w-xs m-auto " + backgroundColor}>
      <div className="flex items-center">
        <div className="flex-shrink-0">{icon}</div>
        <div className="ml-3">
          <h3 className={"font-medium " + fontColor}>{message}</h3>
        </div>
      </div>
    </div>
  );
};

export default Alert;
